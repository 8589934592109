<template>
  <div class="wrapper-container">
    <div class="describe-container-box">
      <div class="title">美蛙品牌数智化业务平台</div>
      <div class="sub-title">全域全渠道五大数字化产品</div>
      <img
        class="main-image"
        src="@/image/brand/brand-business-platform@3x.png"
        alt=""
      />
      <div class="introduce">
        全球蛙品牌商全域全渠道五大数字化产品为品牌商打造一站式数智化体验，解决企业管理、进销存数字化、渠道/经销商管理、公私域联通、大数据分析等需求，提升运营效率，促进生意增长。
      </div>
    </div>
    <img
      :class="imgClassList[i]"
      v-for="(src, i) in enableFuncs"
      :key="src"
      :src="src"
    />
    <action-sheet module="美蛙品牌数智化业务平台">
      <template #extra-title>
        <div class="extra-title">相关文章</div>
      </template>
    </action-sheet>
  </div>
</template>

<script>
import enumerableRoutesMixin from "@/mixins/enumerableRoutes";
export default {
  mixins: [enumerableRoutesMixin],
  data: () => ({
    imgClassList: [
      "large-image-h1292",
      "large-image-h1172",
      "large-image-h1180",
      "large-image-h784",
      "large-image-h1230",
    ],
    enableFuncs: [
      require("@/image/brand/img_1_five_intelligence@3x.png"),
      require("@/image/brand/img_2_five_intelligence@3x.png"),
      require("@/image/brand/img_3_five_intelligence@3x.png"),
      require("@/image/brand/img_4_five_intelligence@3x.png"),
      require("@/image/brand/img_5_five_intelligence@3x.png"),
    ],
  }),
};
</script>

<style scoped lang="scss">
@import "@/assets/styles/solution.common.scss";
.main-image {
  margin-bottom: 40px !important;
}
.introduce {
  margin-top: 0 !important;
  margin-bottom: 40px !important;
  color: $textColor999 !important;
}
.large-image-h1292,
.large-image-h1172,
.large-image-h1180,
.large-image-h784 {
  margin-bottom: 60px;
}
</style>
